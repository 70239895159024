.hero-section-container {
  background-color: rgba(255, 255, 255, 0.9);
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
    height: 60%;
    img {
      height: 90%;
      width: 90%;
    }
  }

  .social-links-wrapper {
    display: flex;

    .fab {
      margin: 0 1em;
      font-size: 3em;
      color: rgba(0, 0, 0, 0.5);
      transition: transform 0.2s;

      &:hover {
        color: rgb(32, 37, 92);
        cursor: pointer;
        transform: scale(1.4);
      }
    }
  }
}

@media (max-width: 768px) {
  .hero-section-container {
    height: 100%;
    padding-bottom: 2em;

    img {
      margin: 0;
    }

    .social-links-wrapper {
      .fab {
        margin: 0.5em;
        font-size: 1.5em;
      }
    }
  }
}
