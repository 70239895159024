.album-bio-container {
  margin: 3em;
  padding-bottom: 2em;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 20px;

  .album-cover {
    @include flexCenter;
    img {
      height: 70%;
      width: 70%;
      border-radius: 10px;
      box-shadow: 0px 14px 21px -5px rgba(0, 0, 0, 0.84);
      -webkit-box-shadow: 0px 14px 21px -5px rgba(0, 0, 0, 0.84);
      -moz-box-shadow: 0px 14px 21px -5px rgba(0, 0, 0, 0.84);
    }
  }

  .album-bio {
    height: 100%;
    width: 90%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bio-header {
      text-decoration: underline;

      h1 {
        margin-bottom: 0;
      }
    }

    button {
      height: 3em;
      width: 6em;
      border-radius: 5px;
      font-size: 1em;
      background-color: rgb(180, 76, 180);
      color: white;
      transition: transform 0.2s;
      box-shadow: 0px 14px 21px -5px rgba(0, 0, 0, 0.84);
      -webkit-box-shadow: 0px 14px 21px -5px rgba(0, 0, 0, 0.84);
      -moz-box-shadow: 0px 14px 21px -5px rgba(0, 0, 0, 0.84);

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}

@media (max-width: 768px) {
  .album-bio-container {
    display: flex;
    flex-direction: column;

    .album-cover {
      margin: 2em;
    }

    .album-bio {
      margin: 0 auto;
    }
  }
}
