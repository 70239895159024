@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap");

@import "./theme.scss";
@import "./navbar.scss";
@import "./heroSection.scss";
@import "./albumBio.scss";
@import "./musicVideos.scss";
@import "./bandBio.scss";
@import "./spotifyPlayer.scss";
@import "./concertImgSlider.scss";
@import "./contact.scss";

body {
  margin: 0;
  font-family: "Oswald", sans-serif;
  background: url("../../public/img/Johnny-Harpoon-Cover3.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  background-color: rgb(180, 76, 180);
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

@media (max-width: 768px) {
  body {
    position: relative;
  }

  body::before {
    content: "";
    position: fixed;
    z-index: -1;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("../../public/img/Johnny-Harpoon-Cover3.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
  }
}
