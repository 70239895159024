.contact-container {
  @include flexCenter;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 60vh;

  .email-wrapper {
    font-size: 1.5em;
    text-align: center;
    margin: 1em;

    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
      transition: transform 0.2s;
      text-decoration: underline;
      font-size: 1.5em;

      &:hover {
        color: rgb(32, 37, 92);
        transform: scale(1.4);
      }
    }
  }

  .social-links-wrapper {
    margin: 1.5em;
    display: flex;

    .fab {
      margin: 0 1em;
      font-size: 3em;
      color: rgba(0, 0, 0, 0.5);
      transition: transform 0.2s;

      &:hover {
        color: rgb(32, 37, 92);
        cursor: pointer;
        transform: scale(1.4);
      }
    }
  }
}

@media (max-width: 768px) {
  .contact-container {
    .email-wrapper {
      font-size: 1em;
    }

    .social-links-wrapper {
      .fab {
        font-size: 1.5em;
      }
    }
  }
}
