.navbar-container {
  background-color: rgba(255, 255, 255, 0.9);

  .navbar-links-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);

    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
    }

    .active-link {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
    }

    h2 {
      margin: 1em 1em 0;
      transition: transform 0.2s;

      &:hover {
        color: rgb(32, 37, 92);
        transform: scale(1.4);
        cursor: pointer;
      }
    }
  }

  i {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    font-size: 2em;
    cursor: pointer;
    color: white;
  }

  .fa-times {
    color: rgba(0, 0, 0, 0.9);
  }
}

@media (max-width: 768px) {
  .navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: transparent;

    i {
      display: block;
    }

    .navbar-links-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.9);

      h2 {
        margin: 2em;
        color: rgba(0, 0, 0, 0.7);
        animation: fadeInAnimation ease-in-out 0.5s;
      }
    }
  }
}
